import cx from 'classnames'
import { NavGroup, NavItems, NavItem } from './types'
import React, { FC, PropsWithChildren, useCallback, useState } from 'react'

import styles from './styles.module.scss'

interface NavMobileProps {
  navItems: NavItems
}

const NavMobile: FC<PropsWithChildren<NavMobileProps>> = ({ children, navItems }) => {
  const [mobileNavigationOpen, setMobileNavigationOpen] = useState<boolean>(false)

  const handleHamburgerClick = useCallback(() => {
    setMobileNavigationOpen(!mobileNavigationOpen)
  }, [setMobileNavigationOpen, mobileNavigationOpen])

  // Only show the mobile nav if there are options to be revealed.
  if (Object.values(navItems).length == 0) return null

  return (
    <div className={cx(styles.navMobile, { [styles.active]: mobileNavigationOpen })}>
      <div className={styles.expanded}>
        <h2 className={styles.navMobileTitle}>{'Get the most out of your biggest asset: your home'}</h2>
        <nav className={styles.items}>
          {Object.values(navItems).map((itemOrGroup) => {
            if (itemOrGroup.type === 'group') {
              const group = itemOrGroup as NavGroup

              return (
                <div
                  style={group.hideOn == 'logged-out' ? { display: 'none' } : undefined}
                  key={group.title}
                  data-hide-on={group.hideOn}
                  className={styles.navMobileGroup}
                >
                  <h3 className={styles.navMobileSubtitle}>
                    {group.href ? <a href={group.href}>{group.title}</a> : group.title}
                  </h3>
                  {group.items.map((item) => (
                    <a
                      style={item.hideOn == 'logged-out' ? { display: 'none' } : undefined}
                      href={item.href}
                      target={item.target}
                      key={item.title}
                      data-hide-on={item.hideOn}
                      className={styles.navMobileGroupItem}
                    >
                      <span className={styles.arrow}>{'→'}</span>
                      {item.title}
                    </a>
                  ))}
                </div>
              )
            }
            const item = itemOrGroup as NavItem

            return (
              <a
                style={item.hideOn == 'logged-out' ? { display: 'none' } : undefined}
                href={item.href}
                target={item.target}
                key={item.title}
                data-hide-on={item.hideOn}
                className={styles.navMobileSubtitle}
              >
                {item.title}
              </a>
            )
          })}
        </nav>
      </div>
      <div className={styles.navMobileHeader}>{children}</div>
      <button className={styles.button} onClick={handleHamburgerClick}>
        <div className={styles.hamburger} />
      </button>
    </div>
  )
}

export default NavMobile
